a {
	color: $dg;
	&:hover, &:focus {
		color: $theme-color;
	}
}

.quick-view {
	.invoice {
		display: none;
	}
}

.rev_slider {
	max-width: 1920px;
	margin: 0 auto;
}

.button-credit {
	padding: 0 5px;
}

#button-cart {
	padding: 0 45px;
}

#popup {
	display: none;
	margin-left: -375px;
	position: fixed;
	left: 50%;
	top: 22%;
	z-index: 2999;
	width: 750px;
	height: 340px;
	background: url(/image/popup/bck.png) no-repeat center center $theme-color;
	padding: 35px 20px 45px 20px;
	color: $white;
	#pu-close {
		position: absolute;
		top: 0;
		right: 0;
		width: 16px;
		height: 16px;
		background: url(/image/popup/cancel.png) no-repeat center center transparent;
		background-size: 15px;
		cursor: pointer;
		padding: 15px;
		filter: invert(100%);
	}
	.popup-content {
		position: relative;
		display: flex;
		.col-icons {
			width: 40%;
			display: flex;
			flex-wrap: wrap;
			.pu-icon {
				background: transparent no-repeat top center;
				background-size: 53px;
				width: 50%;
				padding: 67px 10px 0;
				margin-bottom: 24px;
				margin-top: 10px;
				text-align: center;
				line-height: 16px;
			}
		}
		.col-form {
			width: 60%;
			padding: 0 25px;
			h2 {
				margin: 0 0 10px;
				font-size: 28px;
				text-transform: none;
				line-height: 28px;
				color: $white;
			}
			h3 {
				text-align: center;
				padding-top: 60px;
				color: $white;
			}
			.pu-form {
				margin: 25px 0;
				position: relative;
			}
			.pu-error {
				position: absolute;
				top: 40px;
				color: #800;
				display: none;
			}
			#pu-email {
				background: #fff !important;
				border: none !important;
				margin-right: 0;
				width: 230px;
				height: 35px;
				font-size: 14px;
				color: #333 !important;
				padding: 8px 15px;
			}
			#pu-submit {
				padding: 0 14px;
				height: 35px;
				font-size: 15px;
				font-weight: 600;
				color: $theme-color;
				border: 1px solid $white;
				background: $white;
				&:hover {
					background: $theme-color;
					color: $white;
				}
			}
			.pu-agree {
				font-size: 11px;
				a {
					text-decoration: underline;
					color: $white;
					&:hover {
						color: $white;
						text-decoration: none;
					}
				}

			}
		}
	}
	@media (max-width: 767px) {
		top: 20%;
		margin-left: -150px;
		width: 300px;
		height: 335px;
		padding: 20px;
		.popup-content {
			.col-icons {
				display: none;
			}
			.col-form {
				width: 100%;
				h2 {
					font-size: 25px;
					line-height: 30px;
				}
				p {
					display: none;
				}
				#pu-email {
					margin-bottom: 10px;
					width: 100%;
				}
				#pu-submit {
					width: 100%;
				}
			}
		}
	}
}

.main-title {
	position: absolute;
	left: -9999rem;
	top: -9999rem;
}

.product-info {
	margin-top: 24px;
}

#tab-description {
	img {
		height: auto;
		padding: 15px;
	}
}

.cheap_invoice a {
	display: block;
}

#totop {
	position: fixed;
	right: 10px;
	bottom: 10px;
	cursor: pointer;
	width: 40px;
	height: 40px;
	background-color: $theme-color;
	text-indent: -9999px;
	z-index: 999;
	border: 1px $white solid;
	@include transition(200ms all ease-in-out);
	span {
		position: absolute;
		top: 50%;
		left: 50%;
		margin-left: -8px;
		margin-top: -12px;
		height: 0;
		width: 0;
		border: 8px solid transparent;
		border-bottom-color: #ffffff;
	}
	&:hover {
		background-color: $white;
		border-color: $theme-color;
		span {
			border-bottom-color: $theme-color;
		}
	}
}

#content {
	min-height: calc(100vh - 473px);
}

.homebuilder {
	.panel-heading {
		padding: 0;
		border-bottom: 1px #ccc solid;
		.panel-title {
			display: inline-block;
			font-size: 24px;
			font-weight: 500;
			padding: 10px 15px;
			border-bottom: 1px solid $theme-color;
			position: relative;
			bottom: -1px;
		}
	}
	.panel-body {
		padding: 20px 5px;
	}
}

.shopping-cart-table {
	background: $white;
}

.tp-bullets .counter {
	display: none !important;
}

#coupon_form {
	padding: 15px 0;
}

#image-additional-carousel {
	.owl-next, .owl-prev {
		top: 40px;
	}
	.owl-next {
		right: 3px;
	}
	.owl-prev {
		left: 3px;
	}
	@media (max-width: $screen-sm-max) {
		.owl-next, .owl-prev {
			top: 31px;
		}
	}
	@media (max-width: $screen-xs-max) {
		.owl-next, .owl-prev {
			top: 40px;
		}
	}
	@media (max-width: $screen-xs-max) {
		.owl-next, .owl-prev {
			top: 30%;
		}
	}
}

.widget-products {
	ul.nav {
		text-align: center;
		li {
			float: none;
			display: inline-block;
		}
	}
}

#tab-description {
	img {
		max-width: 100%;
		height: auto !important;
	}
}

#tab-specification {
	overflow-x: auto;
	table {
		background: #fff;
	}
}

.product-compare-header {
	position: fixed;
	top: 250px;
	right: 0;
	width: 110px;
	background: white;
	border: 2px solid $text-color;
	border-right: none;
	border-radius: 5px 0 0 5px;
	padding: 10px;
	z-index: 999;
	text-align: center;
	p {
		margin: 0;
		&#compare-total {
			font-weight: bold;
			line-height: 14px;
		}
	}
	i {
		font-size: 40px;
	}
	.badge {
		background: transparent;
		color: $dg;
	}
}

.contact-icon {
	display: inline-block;
	width: 50px;
	height: 50px;
	border-radius: 50%;
	background: $theme-color;
	top: 0;
	position: absolute;
	i.fa {
		color: $white;
		font-size: 30px;
		padding: 11px 10px 10px 10px;
		width: 50px;
		height: 50px;
		text-align: center;
	}
}

.contact-text {
	display: inline-block;
	padding-left: 60px;
	min-height: 60px;
	margin-bottom: 10px;
	line-height: 24px;
	div.contact-text-b-days div {
		display: inline-block;
		&:first-child {
			width: 170px;
		}
	}
}

.search_block {
	overflow: visible;
	form {
		margin: 0;
		button {
			border: none;
		}
	}
	.dropdown-menu.autosearch {
		margin: 0;
		width: 100%;
		min-width: 0;
		padding: 0;
		right: 0;
		display: none;
		li {
			@include transition(0.2s all);
			display: inline-block;
			width: 100%;
			margin-bottom: 5px;
			cursor: pointer;
			line-height: 1;
			position: relative;
			&:hover {
				background: #fafafa;
			}
			&:last-child:hover {
				background: $white;
			}
			> div {
				line-height: 1.5;
				display: inline-block;
			}
			a {
				padding: 3px 5px;
			}
			.as-image {
				float: left;
				padding: 5px;
				width: 80px;
				margin-right: 10px;
				img {
					height: auto;
					width: 100%;
				}
			}
			.as-title {
				width: calc(100% - 115px);
				font-size: 16px;
				margin: 10px 0;
				white-space: normal;
				overflow: hidden;
				line-height: 19px;
				a {
					color: $black;
				}
			}
			.as-price {
				width: calc(100% - 95px);
				font-size: 14px;
				font-weight: 600;
				margin-left: 90px;
				.price-old {
					font-size: 16px;
					text-decoration: line-through;
					margin-right: 7px;
				}
			}
			#showall {
				display: inline-block;
			}
		}
		@media (max-width: 1199px) {
			width: 100%;
			li {
				.as-image {
					width: 80px;
				}
				.as-title {
					width: calc(100% - 90px);
					font-size: 14px;
					font-weight: 500;
					line-height: 16px;
				}
				.as-price {
					width: calc(100% - 95px);
					left: 80px;
				}
			}
		}
		@media (max-width: 767px) {
			width: calc(100% + 15px);
			left: 0;
		}
		@media (max-width: 530px) {
			width: calc(100% + 90px);
		}
		@media (max-width: 421px) {
			width: calc(100vw - 30px);
			left: auto;
			right: -90px;
		}
	}
}

.widget-products {
	.product-grid .product-block {
		margin: 5px;
		width: auto;
	}
}

h1 {
	padding: 15px 0;
	text-transform: none;
}

.comparetable {
	padding: 15px;
}

.owl-prev, .owl-next {
	position: absolute;
	top: 50%;
	padding: 10px;
	background: rgba(0, 0, 0, .4);
	width: 30px;
	height: 40px;
	color: #fff;
	@include transition(0.2s ease all);
	&:hover {
		background: rgba(0, 0, 0, .8);
	}
}

.owl-prev {
	left: 0;
}

.owl-next {
	right: 0;
}

.page-product .btn-sm.button-credit {
	padding: 0 5px;
}

#header-layout .holiday_works {
	position: absolute;
	width: 150px;
	color: #fff;
	border-color: #fff;
	right: 70px;
	top: 45px;
	z-index: 999;
	@media (max-width: $screen-md-max) {
		right: 44px;
	}
	@media (max-width: $screen-sm-max) {
		right: 47px;
	}
	@media (max-width: $screen-xs-max) {
		right: calc((100vw - 300px) / 2);
	}
}

#i3d {
	.modal-dialog {
		width: 700px;
	}
	.modal-header {
		height: 35px;
		border: 0;
	}
	.modal-body {
		height: 520px;
	}
}

.modal-open {
	margin-right: 0;
}

#i3dtoggle {
	text-align: center;
	span {
		font-size: 20px;
		line-height: 20px;
		cursor: pointer;
	}
	@media (max-width: $screen-xs-max) {
		display: none;
	}
}

#i3dbody {
	ol {
		display: none;
		list-style: none;
		margin: 0;
		padding: 0;
	}
	li {
		list-style: none;
		margin: 0;
		padding: 0;
		img {
			position: absolute;
			top: 0;
			height: auto;
			&.previous-image {
				visibility: hidden;
			}
			&.current-image {
				visibility: visible;
			}
		}
	}
	.basel-threed-view {
		position: relative;
		overflow: hidden;
		margin: 0 auto;
		cursor: ew-resize;
	}
	.spinner {
		width: 60px;
		display: block;
		margin: 0 auto;
		height: 30px;
		background: #333;
		background: rgba(0, 0, 0, 0.7);
		-webkit-border-radius: 5px;
		-moz-border-radius: 5px;
		border-radius: 5px;
		span {
			font-size: 12px;
			font-weight: bolder;
			color: #fff;
			text-align: center;
			line-height: 30px;
			display: block;
		}
	}
	.nav_bar {
		position: absolute;
		bottom: 10px;
		right: 10px;
		z-index: 11;
		a {
			display: block;
			width: 32px;
			height: 32px;
			float: left;
			background: url(/image/i/sprites.png) no-repeat;
			text-indent: -99999px;
			&.nav_bar_play {
				background-position: 0 0 !important;
			}
			&.nav_bar_previous {
				background-position: 0 -73px !important;
			}
			&.nav_bar_stop {
				background-position: 0 -37px !important;
			}
			&.nav_bar_next {
				background-position: 0 -104px !important;
			}
		}
	}
}

.table-responsive-custom {
	td {
		&.text-center {
			&.column-image {
				position: relative;

				span {
					&.product-label {
						&.product-label-special {
							left: 0;
							font-size: 9px;
							height: 20px;
						}
					}
				}
			}
		}
	}
}

#blackfriday {
	position: fixed;
	bottom: 0;
	width: 100%;
	background: $theme-color;
	z-index: 9999;
	text-align: center;
	color: #fff;
	text-transform: uppercase;
	font-weight: 500;
	font-size: 25px;
	line-height: 50px;
	span.text {
		padding: 0 50px;
		display: inline-block;
	}
	.fa {
		position: absolute;
		right: 15px;
		top: 50%;
		margin-top: -12px;
		font-size: 25px;
		cursor: pointer;
	}
}

.page-themecontrol-product {
	#blackfriday {
		display: none;
	}
	.storage_widget {
		display: none;
	}
}

.vc_pageable-slide-wrapper {
	display: flex;
	flex-wrap: wrap;
	justify-content: space-between;
	.vc_grid-item {
		width: 30%;
	}
	.vc_pageable-slide-wrapper .vc_grid-item div {
		line-height: 0;
	}
	img.vc_gitem-zone-img {
		padding: 0;
	}
}

.text_under_title {
	font-size: 24px;
	color: #000;
	font-weight: 600;
}