body .owl-carousel {
	background: transparent;
	@include box-shadow(none);
}

// Module fitter
.filter {

	.panel-body {
		padding: 0;
	}
	h6 {
		font-size: $font-size-base + 2;
		font-family: $font-family;
		font-weight: 400;
		display: inline-block;
		vertical-align: top;
		margin: 0;
	}
	.list-group-item {
		position: relative;
		padding: 25px;
		&:before {
			content: "";
			position: absolute;
			top: 0;
			left: 0;
			@include size(100%, 1px);
			background: $border-color;
		}
		&:first-child {
			&:before {
				content: none;
			}
		}
		.icon-v3 {
			@include size(25px, 25px);
			display: inline-block;
			background: url("#{$image-theme-path}icon-3.png") no-repeat;
			margin-right: 5px;
		}
		&:nth-child(2) {
			.icon-v3 {
				background-position: 0 -43px;
			}
		}
		&:nth-child(3) {
			.icon-v3 {
				background-position: 0 -85px;
			}
		}
	}
}

/* search */
#searchtop {
	input.form-control {
		min-height: 54px;
		border: none;
	}
	.btn-search {
		@include size(16px, 16px);
		display: block;
		background: url("#{$image-theme-path}bt-search.png") no-repeat;

	}
}

.form-control::-moz-placeholder {
	color: $link-color;
}

// module cart
#cart {
	&.open .dropdown-toggle {
		box-shadow: none;
	}
	a {
		display: inline-block;
		position: relative;
	}
	.icon-cart {
		background: $pink;
		@include size(55px, 47px);
		display: inline-block;
		margin-left: 40px;
		position: relative;
		text-align: center;
		i {
			font-size: 30px;
			padding: 8px;
		}
	}
	.wrap-cart {
		display: inline-block;
		color: $white;
		span {
			position: relative;
			top: 13px;
		}
		.cart-total {
			font-weight: bold;
			text-transform: capitalize;
		}
	}
	.dropdown-menu {
		min-width: 350px;
		left: auto;
		right: 0;
		padding: 0;
		text-transform: inherit;
		color: $black;
		font-size: $font-size-base;
		li {
			&:first-child {
				.table td {
					padding: 10px;
				}
			}
			a {
				color: $black;
				text-transform: capitalize;
				font-weight: normal;
			}
		}
		.table-cart {
			background: #d1d1d1;
			.btn {
				border: 1px solid rgba(255, 255, 255, 0.54);
				color: $black;
				text-transform: uppercase;
				padding: 0 20px;
				&:hover {
					background: $white;
					color: $black;
				}
			}
			.table {
				> tbody {
					> tr {
						> td {
							color: $black;
							padding: 5px;
							border-bottom: 1px dotted #555;
						}
					}
				}
			}
		}
	}
	.price-cart {
		color: $theme-color;
	}
	.btn-remove {
		@include size(14px, 14px);
		background: $black;
		color: $white;
		font-size: $font-size-base - 6;
		font-weight: normal;
		line-height: 14px;
		padding: 0;
		text-align: center;
		&:hover {
			background: $theme-color;
		}
	}
}

/* bestseller */
.bestseller {
	&.panel-warning {
		.products-block {
			border: 2px solid $brand-warning;
			border-top: none;
		}
	}
}

/* pagination */
.pagination {
	li {
		span, a {
			@include border-radius($border-color, 3px)
		}
	}
}