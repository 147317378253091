/*
 * Off Canvas
 * --------------------------------------------------
 */
@media screen and (max-width: $screen-md-min) {
	.row-offcanvas {
		position: relative;
		@include transition(all 0.25s ease-out);
	}
	.row-offcanvas-right {
		right: 0;
	}
	.row-offcanvas-left {
		left: 0;
	}
	.row-offcanvas-right
	.sidebar-offcanvas {
		right: -70%;
	}
	.row-offcanvas-left
	.sidebar-offcanvas {
		left: -70%;
	}
	.row-offcanvas-right.active {
		right: 70%;
	}
	.row-offcanvas-left.active {
		left: 70%;
	}
	.sidebar-offcanvas {
		position: absolute;
		top: 0;
		width: 70%;
		ul, ol {
			padding: 0;
			li {
				list-style: none;
			}
		}
		.tree-menu ul li:hover {
			background-color: $white;
		}
		.offcanvas-close {
			margin: 5px 10px;
			border-radius: 50%;
			border: none;
			background: $theme-color;
			width: 25px;
			height: 25px;
			padding: 1px;
			color: $white;
		}
	}
	.bottom-offcanvas {
		border-top: 1px solid $border-color-base;
		padding: 10px 0;
	}
}

#offcanvasmenu {
	.nav .caret {
		display: none;
	}
	.pavo-widget .widget-heading {
		margin-top: 15px;
	}
	.navbar-nav {
		float: none;
		margin: 0;

		> li {
			float: none;
			background-color: $theme-color;
			border-right: none;
			&:hover:after {
				display: none;
			}
			.click-canavs-menu {
				cursor: pointer;
				display: inline-block;
				padding: 15px 19px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: #fff;
			}
			li.dropdown-submenu {
				.click-canavs-menu {
					padding: 10px 25px;
				}
				li.dropdown-submenu {
					> .click-canavs-menu {
						padding: 10px 15px;
					}
				}
			}
			> a {
				display: block;
				@include box-shadow(inset 0 -1px rgba(0, 0, 0, 0.2));
				text-transform: uppercase;
				color: $white;
			}
		}
	}
	.dropdown-menu {
		border: 0 none;
		border-radius: 0;
		box-shadow: none;
		display: none;
		float: none;
		margin: 0;
		padding: 0 10px;
		position: static;
		visibility: visible;
		width: auto !important;
		min-width: 160px;
		@include opacity (1);
		a {
			font-size: 13px;
			padding: 5px 0;
			display: block;
			color: $text-color;
		}
	}
	.w-product {
		padding: 0;
	}
	.product-block {
		border: none;
		padding: 0;
		.image {
			float: none;
			margin: 0;
		}
	}
	.cat_all {
		& > li {
			position: relative;
			.click-allcat {
				cursor: pointer;
				display: inline-block;
				padding: 1px 15px;
				top: 20px;
				position: absolute;
				right: 0;
				z-index: 1;
				color: $black;
				&.fa-minus-square:before {
					content: '\f067';
				}
				&.fa-plus-square:before {
					content: '\f068';
				}
			}
			&.haschildren {
				ul {
					display: none;
				}
				& > a {
					width: calc(100% - 40px);
					display: inline-block;
				}
				&.opened ul {
					display: block;
				}
			}
		}
	}
}

.canvas-menu {
	margin: 8px;
}