//
// Navs
// --------------------------------------------------
$hot-color: #ff9800 !default;
.badges {
	&.new, &.hot {
		color: $white;
		font-size: $font-size-base - 3;
		height: 15px;
		line-height: 16px;
		padding: 0 5px;
		position: absolute;
		left: 20px;
		@include rounded-corners(2px);
		text-align: center;
		text-transform: uppercase;
		top: -5px;
		transform: translateZ(0px);
		font-family: $font-family-base;
		font-weight: 500;
		&:before {
			border-style: solid;
			border-width: 2px;
			bottom: -5px;
			content: "";
			left: 4px;
			position: absolute;
		}
	}

	&.new {
		background-color: $brand-info;
		&:before {
			border-color: $brand-info transparent transparent;
		}
	}

	&.hot {
		background-color: $hot-color;
		&:before {
			border-color: $hot-color transparent transparent;
		}
	}

}

.red {
	.menu-title {
		color: $red;
	}
}

.#{$app-brand-prefix}-megamenu {
	.cat_all {
		li.haschildren {
			&:after {
				content: '\f105';
				font-family: "FontAwesome";
				color: $theme-color;
				position: absolute;
				right: 13px;
				top: calc(50% - 10px);
				font-size: 20px;
			}
			&:hover:after {
				color: $white;
			}
		}
		ul.children {
			position: absolute;
			left: calc(100% - 5px);
			top: 0;
			background: $white;
			box-shadow: 0 6px 12px rgba(0, 0, 0, 0.175);
			visibility: hidden;
			opacity: 0;
			@include transition(.2s opacity ease);
			z-index: 1;
			li {
				padding: 12px 20px;
				width: 240px;
			}
		}
		& > li:hover {
			& > ul.children {
				opacity: 1;
				visibility: visible;
				a {
					color: $megamenu-sub-link-color;
				}
				li:hover a {
					color: $white;
				}
			}
		}
	}
	.navbar {
		margin: 0;
		border: 0;
	}
	.navbar-collapse {
		padding: 0;
	}
	.navbar-nav {
		> li {
			border-right: 1px solid $border-color;
			&:first-child {
				position: relative;
				&:before {
					top: -1px;
					left: -1px;
				}
				&:after {
					bottom: -1px;
					right: -1px;
				}
			}
			&.aligned-fullwidth {
				position: static;
				> .dropdown-menu {
					left: 0;
				}
				.dropdown-submenu {
					.dropdown-menu {
						margin-left: 0;
					}
				}
			}
			&.aligned-right {
				.dropdown-menu {

					left: auto;
					right: 0;
				}
				.dropdown-submenu {
					.dropdown-menu {
						margin-right: 100%;
					}
				}
			}
			&.aligned-left {
				.dropdown-menu {
					left: 0;
					right: auto;
				}
				.dropdown-submenu {
					.dropdown-menu {
						left: 100%;
					}
				}
			}
			&.aligned-center {
				.dropdown-menu {
					left: 50%;
					transform: translate(-50%);
				}
				.dropdown-submenu {
					.dropdown-menu {
						margin-left: 100%;
					}
				}
			}
			> .dropdown-menu {
				top: 120%;
				@include opacity(0);
				@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
				display: block;
				position: absolute;
				visibility: hidden;
			}
			&:hover,
			&:focus {
				> .dropdown-menu {
					@include opacity(1);
					visibility: visible;
					display: block;
					top: 100%;
				}
			}

			> a {
				padding: $megamenu-parent-padding;
				font-size: $megamenu-link-font-size;
				color: $megamenu-link-color;
				background-color: transparent !important;
				@include transition(all 350ms ease-out);
				line-height: 22px;
				&:hover, &:focus, &:active {
					color: $theme-color;
					.caret:before {
						color: $theme-color !important;
					}
				}
				.caret {
					border: 0;
					position: relative;
					&:before {
						content: '\f107';
						font-family: 'FontAwesome';
						position: absolute;
						right: -10px;
						top: -12px;
						font-size: $font-size-base - 1;
						font-weight: 100;
						color: $theme-color;
					}
				}
			}
			&:hover {
				> a .caret:before, &:active > a .caret:before {
					color: $megamenu-link-hover-color;
				}

			}
			&.full-width {
				position: static;
			}
		}
	}
	/* level 2 */
	.dropdown-menu {
		top: 100%;
		left: -9999px;
		@include rounded-corners(3px);
		height: auto;
		color: $text-color;
		border-top: 1px solid $border-color;
		min-width: $megamenu-sub-min-width;
		padding: 0;
		background-color: $megamenu-sub-bg-color;
		@include rounded-corners(3px);
		@include transition(opacity 0.3s linear 0s);
		z-index: 9999;
		ul, ol {
			padding: 0;
		}
		li {
			line-height: 20px;
			list-style: none;
			position: relative;
			a {
				color: $megamenu-sub-link-color;
				text-transform: uppercase;
				font-size: $font-size-base - 2;
				font-weight: 500;
				&:hover {
					background-color: transparent;
				}
			}
		}
	}
	.dropdown {
		&:hover {
			> .dropdown-menu {
				display: block;
				left: 0;
			}
			> a {
				.fa {
					color: $megamenu-link-hover-color;
				}
			}
		}
	}
	/* level > 3 */
	.dropdown-submenu {
		position: relative;
		.dropdown-menu {
			top: 0;
			right: -100%;
			left: auto;
			position: absolute;
		}
		&:hover {
			> .dropdown-menu {
				left: 100%;
				display: block;
				top: 0;
			}
		}
	}
	.mega-group {
		& > .dropdown-toggle {
			border: 0;
			display: block;
			letter-spacing: 3px;
			text-transform: uppercase;
			font-family: $megamenu-heading-title;
			color: $megamenu-heading-color;
			.fa {
				color: $white;
			}
		}
	}
	.widget-heading {
		font-size: $font-size-base + 2;
		margin: 0 0 20px;
	}
	.widget-images {
		margin-bottom: 20px;
		padding-top: 5px;
	}
	.megamenu .cols1 {
		min-width: 200px;
	}
	.megamenu .cols2 {
		min-width: 500px;
	}
	.megamenu .cols3 {
		min-width: 740px;
	}
	.sidebar .product-block {
		padding: 5px 0;
		border-bottom: none;
		.image {
			padding: 0;
		}
	}
}