@media (max-width: $screen-md-max) {
	.pav-megamenu .navbar-nav > li > a {
		padding: 16px 13px;
		font-size: 15px;
	}
	.productdeals-v1 {
		.item-countdown {
			li {
				width: 35px;
				margin: 0 7px 20px;
			}
		}
		.product-block {
			.product-meta {
				padding: 0 10px 20px;
			}
		}
	}
	.producttabs {
		.btn-dark {
			@include size(30px, 30px);
			font-size: $font-size-base - 3;
			line-height: 30px;
		}
	}
	.subcategories li {
		width: calc(100% / 5);
	}
	.feature-box-v1 {
		padding: 12px 20px;
		.fbox-body.white strong {
			font-size: 18px;
		}
	}
	.home3 {
		.featured-category {
			ul li {
				.caption {
					padding: 20px 5px;
				}
			}
			.image {
				margin: 5px 0 5px 10px;
			}
		}
	}
	.product-info {
		.product-meta {
			.btn-sm, .btn-group-sm > .btn {
				padding: 0 5px;
			}
		}
	}
}

@media (max-width: $screen-md-max) and (min-width: $screen-md) {
	.home3 {
		.widget-blogs {
			.carousel-controls-v1 {
				top: 45px;
				.carousel-control.left {
					left: -65px;
				}
				.carousel-control {
					border: none;
				}
			}
		}
	}
}

@media (min-width: 992px) {
	.click-allcat {
		display: none;
	}
}

/*$media (max-width: 991px) {*/
@media (max-width: $screen-sm-max) {
	.widget-images {
		margin-bottom: 30px;
	}
	.subcategories li {
		width: calc(100% / 4);
	}
	.home3 .layerslider-wrapper {
		margin-bottom: 30px;
	}
	.header-top .btn-group {
		margin-right: 10px;
	}
	.panel-v1 > .panel-heading .panel-title {
		font-size: 18px;
	}
	.productdeals-v1 .product-block .product-meta {
		text-align: center;
	}
	.newsletter-v1 .panel-body ul li {
		line-height: 23px;
	}
	.tab-v1 .nav-tabs > li > a {
		padding: 0 10px;
	}
	.footer-center {
		padding-top: 80px;
		.panel {
			margin-bottom: 15px;
		}
	}
	.featured-category {
		ul li {
			.caption {
				padding: 20px 5px;
			}
		}
		.image {
			margin: 5px 0 5px 10px;
		}
	}
	.zoomContainer {
		display: none;
	}
	.product-grid {
		.product-block {
			width: calc(100% / 3 - 13px);
			.action > div {
				padding-right: 2px;
			}
		}
	}
	.cart-container {
		width: 80px;
		right: 0;
	}
	#powered {
		.payment-img {
			img {
				&:first-child {
					display: none;
				}
			}
		}
	}
	.payment2row {
		display: block;
	}
}

@media (max-width: $screen-sm) {
	.alert {
		margin: 10px 15px;
	}
	.quickview, .zoom {
		display: none !important;
	}
}

/*$media (max-width: 767px) {*/
@media (max-width: $screen-xs-max) {
	.product-grid {
		.product-block {
			width: calc(100% / 2 - 12px);
		}
	}
	.logo-container {
		width: 140px;
	}
	.topcontacts {
		width: calc(100% - 140px);
	}
	.featured-category ul li {
		width: 100%;
	}
	#powered ul.links {
		margin-bottom: 40px;
	}
	.tab-v3 .nav-tabs > li > a {
		padding: 10px 5px;
		margin-bottom: 10px;
		font-size: 18px;
	}
	.table-responsive {
		.input-group {
			.form-control {
				margin-right: 30px;
				padding: 5px;
			}
		}
	}
	.cart.pull-left {
		float: none !important;
		margin-bottom: 5px;
	}

	.checkout-cart table, .shopping-cart-table {
		background: #fff;
	}
	.shopping-cart-table {
		& > thead {
			display: none;
		}
		.shopping-cart-table__product-image img {
			max-width: 160px;
		}
		td.image-col {
			width: 100%;
			text-align: center !important;
		}
		&, & tbody, & td, &.tr {
			display: block;
			border: 0;
			width: 100%;
		}
		td {
			position: relative;
			padding-left: 43% !important;
			text-align: left !important;
			white-space: normal !important;
			&:first-child {
				border-top: 0;
			}
			div.th-title {
				display: block;
				position: absolute;
				left: 10px;
				width: 38%;
				padding-right: 10px;
				text-align: right;
				top: 50%;
				-webkit-transform: -webkit-translateY(-50%);
				-ms-transform: translateY(-50%);
				transform: translateY(-50%);
				font-weight: bold;
				text-transform: uppercase;
			}
			&.no-title {
				padding-left: 20px !important;
				text-align: center !important;
			}
		}
		.input-group-qty {
			display: inline-block;
			padding-right: 10px;
		}
	}

	.total-table {
		.text-right {
			padding-left: 60% !important;
		}
		td div.th-title {
			width: 53% !important;
		}
	}

	.shopping-cart-btns {
		text-align: center;
		.btn.pull-left, .btn.pull-right {
			float: none !important;
			margin: 0 !important;
		}
	}
	.cart-container {
		position: absolute;
		top: 105px;
		z-index: 999;
		#cart {
			.icon-cart {
				margin: 0 10px 0 0;
				height: 36px;
				width: 40px;
			}
			.fa-shopping-cart {
				font-size: 22px;
				padding: 7px;
			}
		}
	}
	.widget-raw-html.art_main .widget-inner .am_col {
		&:first-child {
			width: 100%;
		}
		&:last-child {
			display: none;
		}
	}
	.fancybox-opened {
		width: 90% !important;
		position: fixed !important;
		left: 5% !important;
		z-index: 999999 !important;
		top: 35% !important;
		.fancybox-skin {
			padding: 15px 0 !important;
		}
	}
	.fancybox-inner {
		width: 100% !important;
		height: auto !important;
	}
	.holiday_table {
		width: 100% !important;
		td {
			font-size: 15px !important;
		}
	}
	#tab-description {
		font-size: 14px;
		.vc_row-flex, .vc_row-fluid {
			flex-wrap: wrap;
			div[class^="vc_col"] {
				line-height: 16px;
			}
			.vc_column_container {
				width: 100%;
			}
		}
	}
	#powered .inner {
		flex-wrap: wrap;
	}
	.footer-name span {
		display: block;
	}
	.subcategories li {
		width: calc(100% / 3);
	}
}

@media (max-width: $screen-xs) {
	.refine-search .panel-body {
		padding: 15px 5px;
	}
	.subcategories li {
		width: calc(100% / 2);
	}
	.product-filter .filter-right > div {
		margin-bottom: 5px;
	}
	.product-list .product-col .block-img {
		float: none;
		width: 100%;
	}
	.product-list .product-meta {
		padding: 20px;
	}
	.tab-v6 .nav-tabs > li > a {
		padding: 15px 10px;
		font-size: $font-size-base - 1;
	}
	.tab-v7 .nav-tabs > li.active::before {
		content: none;
	}
	.pav-blogs .blog-body {
		overflow: visible;
	}
	.logo-store a > span {
		font-size: 10px;
	}
	.product-grid {
		.product-block {
			width: 100%;
		}
	}
}
@media (max-width: 375px) {
	.product-markdown-view {
		.credit-btn-group {
			flex-direction: column;

			.button-credit {
				width: 100%;
			}
		}
	}
}