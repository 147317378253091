/******************************************************
 * $package Pav Opencart Theme Framework for Opencart 1.5.x
 * $version 1.1
 * $author http://www.pavothemes.com
 * $copyright   Copyright (C) Augus 2013 PavoThemes.com <$emai:pavothemes$gmail.com>.All rights reserved.
 * $license     GNU General Public License version 2
 *******************************************************/
html {
	overflow-x: hidden;
}

body {
	&.layout-boxed-lg {
		#page {
			max-width: $container-lg + 20px;
			@include box-shadow(0 0 5px lighten($gray, 50%));
			margin: 0 auto;
			background: $white;
		}
	}
	&[class*="-home"] {
		.header-1 {
			.pav-verticalmenu {
				display: none !important;
				border: 1px solid #000;
			}
			.dropdown {
				&:hover {
					.pav-verticalmenu {
						display: block;
					}
				}
			}

		}
	}
	&[class*="page-"] #content {
		padding: 20px;
		h1 {
			margin-top: 0;
		}
	}
	&[class*="page-category"] {
		#content {
			padding: 0;
		}
	}
	&[class*="page-product"] #content {
		overflow: hidden;
		padding: 0;
		.owl-carousel .owl-wrapper-outer {
			overflow: visible;
		}
		.owl-carousel {
			margin-bottom: 35px;
		}
	}
}

#sys-notification {
	position: fixed;
	top: 10px;
	width: 100%;
	z-index: 9999;
	right: 0;
}

/*
* HEADER 
*/

#topbar {
	background: $topbar-outside-bg;
	font-size: $topbar-font-size;
	color: $topbar-color;
	height: 40px;
	line-height: 25px;
	padding: 8px 0;
	.topbar-left {
		position: relative;
	}
	.custom-top {
		&:nth-child(1) {
			margin-right: 20px;
		}
		i {
			padding-right: 5px;
		}
	}
	.container {
		.inside {
			background: $topbar-bg;
			padding: $topbar-container-padding;
		}
	}

}

.topcontacts {
	text-align: center;
	display: flex;
	justify-content: space-around;
	flex-wrap: wrap;
	&, #cart {
		margin-top: 15px;
	}
	.box-col-inner {
		display: inline-block;
		.media-title {
			float: left;
			font-size: 24px;
			padding: 3px 12px;
		}
		.media-body {
			font-size: 14px;
		}
	}
}

.header-top {
	background-color: $header-top-bg;
	color: $white;
	font-size: $font-size-base - 2;
	font-weight: 500;
	text-transform: uppercase;
	.inside {
		padding: 10px 0 50px;
		& > .row {
			display: flex;
			align-items: center;
		}
	}
	.icon-down {
		background: url("#{$image-theme-path}bullet.png") no-repeat right;
		@include size(8px, 7px);
		margin-left: 5px;
		display: inline-block;
	}
	.btn-group {
		margin-right: 20px;
		button {
			background-color: transparent;
			border: none;
			padding: 0;
		}
		&:hover {
			.dropdown-menu {
				@include opacity(1);
				visibility: visible;
				display: block;
				top: 100%;
				color: $text-color;
			}
		}
		.dropdown-menu {
			min-width: 150px;
			padding: 10px;
			margin-top: 0;
			font-size: $font-size-base - 2;
			@include opacity(0);
			@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
			top: 120%;
			position: absolute;
			visibility: hidden;
			display: block;
			> li {
				a {
					padding: 0;
					color: $text-color;
					&:hover, &:active, &:focus {
						color: $link-hover-color;
					}
				}
				&:hover {
					color: $link-hover-color;
				}
			}

		}
	}
	.quickaccess-toggle {
		cursor: pointer;
		position: relative;
		&:hover {
			.inner-toggle {
				@include opacity(1);
				visibility: visible;
				display: block;
				top: 100%;
			}
		}
		i {
			padding-left: 5px;
			&.first {
				padding-right: 5px;
				font-size: $font-size-base;
			}
		}
	}
	.inner-toggle {
		background: $white;
		min-width: 150px;
		z-index: 99999;
		left: 0;
		padding: 10px;
		@include opacity(0);
		@include transition(all 0.3s cubic-bezier(0.25, 0.46, 0.45, 0.94) 0s);
		display: block;
		top: 120%;
		position: absolute;
		visibility: hidden;
		@include box-shadow(0 6px 12px rgba(0, 0, 0, 0.176));
		@include rounded-corners(3px);
		ul {
			padding: 0;
			li {
				display: block;
				line-height: 24px;
				width: 100%;
				a {
					color: $text-color;
					&:hover {
						color: $theme-color;
					}
				}
				span {
					padding-right: 3px;
				}
			}
		}
	}
}

.header-bottom {
	.inside {
		border: 1px solid $border-color;
		@include rounded-corners(3px);
		margin-top: -25px;
		background-color: $white;
		position: relative;
		z-index: 99;
	}
}

.search-focus {
	color: $search-icon-color;
	cursor: pointer;
	border-right: 1px solid $border-color;
	padding-right: 15px;
	line-height: 32px;
	height: 27px;
}

.nav-search {
	&.open {
		height: 44px;
		top: 0;
		visibility: visible;
		@include opacity(1);
	}
}

/* multi logo */
.logo-store {
	position: relative;
	text-align: center;
	a {
		color: $white;
		vertical-align: middle;
		white-space: nowrap;
		&:hover {
			color: $white;
		}
		& > span {
			position: absolute;
			bottom: -10px;
			left: 0;
			right: 0;
		}
	}
}

#logo-theme {
	a {
		background: url(#{$image-theme-path}logo.png) no-repeat;
		display: inline-block;
		@include size(170px, 37px);
		span {
			display: none;
		}
	}
}

/*
* CONTENT
 */
#pav-masshead {
	.inner {
		@include container-layout-variant($masshead-color, $masshead-bg, $masshead-color, lighten($masshead-color, 5%));
	}
}

.breadcrumbs {
	.container {
		background: transparent;
	}
}

/*
* NAVIGATION
*/
#pav-mainnav {
	.inner {
		background-color: $theme-color-secondary;
	}
}

/*
* SLIDESHOW 
*/
#pavo-slideshow {
}

/*
* SHOWCASE 
*/
#pavo-showcase {
	.inner {
		background-color: $showcase-bg;
	}
}

/*
* PROMOTION TOP 
*/
#pav-promotion {
}

#breadcrumb {
	padding-left: 0;
	padding-right: 0;
}

/*
* MAIN CONTENT
*/

/*end*/
/* FOOTER */
#footer {
	ul, ol {
		margin: 0;
		li {
			line-height: 20px;
			a {
				font-size: $font-size-base - 2;
				color: $footer-color;
				text-transform: uppercase;
				font-weight: 500;
				@include transition(all 0.4s ease 0s);
			}
		}
	}
}

.footer-top {
	background: transparent;
	margin-bottom: -97px;
}

.footer-center {
	background: $silver;
	padding: 30px 0;
	color: $black;
	.box-heading {
		font-weight: 600;
		padding-bottom: 10px;
	}
	.panel {
		background: transparent;
		margin-bottom: 0;
		.panel-heading {
			padding: 0;
		}
		.panel-body {
			padding: 20px 0 0;
		}
		.panel-title {
			color: $black;
		}
	}
}

.footer-bottom {
	background: $theme-color;
	.inside {
		background: url("#{$image-theme-path}pattern.jpg") repeat 0 0;
		position: relative;
		@include rounded-corners(3px);
		.bg-blue {
			background: url("#{$image-theme-path}pattern2.jpg") repeat 0 0;
		}
	}
}

/* POWER BY */
#powered {
	background: $theme-color;
	a {
		font-size: $font-size-base - 2;
		color: $footer-color;
		font-weight: 500;
		text-transform: uppercase;
		&:hover {
			color: $white;
		}
	}
	.copyright {
		color: $white;
		font-size: $font-size-base - 2;
		font-weight: 500;
		text-transform: uppercase;
	}
	ul.links {
		line-height: 16px;
		padding-left: 20px;
		li {
			padding: 0 10px;
		}
	}
	.inner {
		display: flex;
		flex-wrap: wrap;
		align-items: center;
		.oferta p {
			font-size: 12px;
			color: $white;
			margin-bottom: 2px;
		}
	}
	.payment-img {
		img {
			max-height: 61px;
		}
	}
}
.payment2row {
	display: none;
	margin: 8px 0;
}
.for-send-error-press {
	font-style: italic;
	margin: 15px 0;
}