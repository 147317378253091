.owl-carousel {
	display: block !important;
}

.category-info {
	padding: 0;
	.image {
		padding: 0;
		margin-bottom: 19px;
	}
}

.style-panel {
	.panel {
		margin: 15px 0;
		border: 1px solid #ddd;
	}
	.panel-collapse {

	}
	.panel-heading {

	}
}

.sidebar {
	.products-block {
		border: $brand-border;
		@include rounded-corners(3px);
	}
	.product-label {
		display: none;
	}
	.product-col {
		width: 100%;
		clear: both;

	}
	.products-row {
		&:last-child {
			.product-block {
				border-bottom: none;
			}
		}
	}
	.price .price-old {
		font-size: $font-size-base - 1;
	}
	.price .price-new {
		font-size: $font-size-base;
	}
	.product-block {
		overflow: hidden;
		padding: 15px;
		border-bottom: 1px solid $border-color;
		.image {
			padding: 0;
		}
		.block-img {
			float: left;
			margin-right: 10px;

		}
		.top {
			padding: 0;
		}
		.product-meta {
			overflow: hidden;
			text-align: left;
			padding: 0;
		}
		.name {
			padding-top: 0;
			margin-bottom: 0;
		}
	}
	.bottom {
		display: none;
	}
	.product-grid {
		.product-block {
			padding: 15px;
			&:hover {
				.cart, .top {
					margin: 0;
				}
				.image {
					@include opacity(1);
				}
			}
		}
	}

}

.product-info {
	.product-meta {
		.btn-sm, .btn-group-sm > .btn {
			padding: 0 15px;
			&.nostock {
				padding: 0 25px 0 26px;
				margin-left: 0;
			}
		}
	}
}

.subcategories {
	display: flex;
	flex-wrap: wrap;
	align-items: flex-start;
	padding: 0;
	margin: 0;
	li {
		width: calc(100% / 6);
		list-style: none;
		padding: 0 10px;
		text-align: center;
		a {
			margin-top: 10px;
		}
	}
}

.category-description {
	h2 {
		font-size: 24px;
	}
}

.widget-raw-html {
	&.art_main {
		.widget-inner {
			display: flex;
			align-items: stretch;
			.am_col {
				background: #fff;
				padding: 15px;
				width: 50%;
				.widget-heading {
					margin-bottom: 15px;
				}
				a {
					display: inline-block;
					font-size: 16px;
					padding-left: 15px;
				}
			}
		}
	}
}

/* 
 *  LISTING PRODUCTS PAGE 
 */
.product-filter {
	color: $product-filter-color;
	text-transform: uppercase;
	font-size: $product-filter-font-size;
	font-weight: 500;
	span {
		margin-right: 5px;
	}
	.filter-right > div {
		padding-left: 5px;
	}

	.display {
		float: left;
	}
	.form-control {
		display: inline-block;
		padding: 0 20px;
		margin: 0 5px;
		@include size(auto, 36px);
		text-transform: capitalize;
	}
	.btn-switch {
		display: inline-block;
		margin-right: 5px;
		color: #969696;
		background: transparent;
		padding: 0;
		line-height: 35px;
		font-weight: 500;
		font-size: 24px;
		i {
			padding: 0 5px;
		}
		&:hover, &.active {
			color: $theme-color-default;
			@include box-shadow (none);
		}
	}
}

.product-list {
	.description {
		display: block;
	}
	.product-meta {
		padding-top: 20px;
		padding-left: 0;
	}
	.product-block {
		display: flex;
		.block-img {
			width: 30%;
		}
		.bottom {
			padding: 0px 20px;
			.action {
				display: inline-block;
				padding: 4px 0px;
				margin-left: 15px;
			}
			.cart {
				display: inline-block;
			}
		}
	}
}

.product-grid {
	.products-block {
		@include border-radius($border-color, 3px);
	}
	.product-block {
		display: inline-block;
		margin: 5px;
		width: calc(100% / 4 - 13px);
		.cart {
			.btn {
				width: 100%;
				font-size: 18px;
			}
		}
		.bottom {
			@include transition (all 400ms ease 0s);
			bottom: 0;
			left: 0;
			width: 100%;
			z-index: 999;
			padding: 0 20px 20px;
			background-color: $white;
			text-align: center;
		}
		@media (min-width: $screen-xs) {
			&:hover {
				@include box-shadow(0 0 5px #a6a5a5);
				z-index: 1;
				margin-top: 5px;
				margin-bottom: 5px;
				border-top-color: #e1dddd;
			}
		}
	}
}
/* rating in product page */
.prod_rating .stars .fa {
	color: $brand-warning;
}
#scr_revs, #scr_newrev {
	font-weight: 600;
}
#scr_revs:hover, #scr_newrev:hover {
	cursor: pointer;
	color: $theme-color;
}
#button-review, #review-btn {
	padding: 1px 30px 0;
}
#tab-review {
	.btn-review:active, .btn-review:focus {
		outline: none;
	}
	.btn-review:hover {
		cursor: pointer;
		background: #000;
	}
	.col-sm-offset-2.col-sm-10 {
		margin-left: 0;
	}
	textarea {
		resize: none;
	}
	.review {
		margin-bottom: 10px;
		.rating .fa {
			color: $brand-warning;
		}
		.review-header {
			display: flex;
			justify-content: space-between;
			flex-wrap: wrap;
			padding: 15px;
			border-bottom: none;
			background-color: $well-bg;
		}
		.review-main {
			padding: 15px;
			.review-title {
				font-weight: 500;
			}
		}
		.review-header {
			.review-left-header {
				display: flex;
				font-weight: 600;
				color: $headings-color;
			}
			.review-author {
				margin-right: 10px;
			}
		}
	}
	.author > span:first-child {
		font-weight: 600;
		margin-right: 7px;
	}
	.stars-block {
		color: $brand-warning;
	}
	.review-list {
		padding: 15px;
		border: 1px solid $gray-lighter;
		margin-bottom: 10px;
		span {
			font-weight: 600;
		}
	}
	.fa-star, .fa-star-o {
		font-size: 18px;
	}
	.fa-star-o {
		color: $gray-lighter;
	}
}
#form-review-container {
	width: 100%;
	.set-rating {
		display: flex;
		flex-direction: row-reverse;
		justify-content: flex-end;
		margin-top: 6px;
		margin-bottom: 2px;
		height: 16px;
		width: 100px;
		i {
			margin: 0px;
			display: block;
			float: left;
			padding: 0px 4px 0px 0px;
			cursor: pointer;
			color: $gray-lighter;
			&.selected {
				color: $brand-warning;
			}
		}
		&:hover i {
			color: $gray-lighter;
		}
		& > i:hover, & > i:hover ~ i {
			color: $brand-warning;
		}
	}
	.recaptcha_container .control-label {
		display: none;
	}
}
#products {

	.rating {
		.fa {
			color: $brand-warning;
			text-align: center;
		}
	}
	&.product-grid {
		.rating {
			position: absolute;
			top: -10px;
			width: calc(100% - 40px);
			text-align: center;
		}
	}
	&.product-list {

	}
}
/*
* PRODUCT DEALS
*/
.productdeals {
	.widget-inner {
		.carousel {
			position: static;
		}
	}
}

/*
* PRODUCT INFORMATION PAGE
*/
.product-info {
	.box-product-infomation {
		margin-top: 20px;
	}
	.title-product {
		font-size: $font-size-base + 3;
		margin-top: 0;
		text-transform: capitalize;
		font-weight: 600;
	}
	.image-additional {
		overflow: hidden;
		.owl-carousel {
			margin-bottom: 0 !important;
		}
	}
	.image-additional .item {
		border: 1px solid $border-color;
		text-align: center;
		margin: 0 2px;
		background-color: $white;
		position: relative;
		a.active {
			&:before {
				position: absolute;
				width: 100%;
				height: 2px;
				background-color: $theme-color;
				content: "";
				left: 0;
				z-index: 1;
				top: -1px;
			}
			&:after {
				position: absolute;
				left: 50%;
				margin-left: -8px;
				border: 6px solid transparent;
				border-bottom: 6px solid $theme-color;
				content: "";
				z-index: 1;
				top: -12px;
			}
		}
	}
	.thumbs-preview {
		margin-top: 20px;
		a + a {
			margin-left: 10px;
		}
	}
	.vertical {
		&.image-additional {
			.carousel-control {
				display: none;
			}
		}
		&:hover {
			.carousel-control {
				display: block;
			}
		}

		.center {
			left: 0;
			right: 0;
			margin: 0 auto;
		}

		.top {
			top: 0;
			bottom: auto;
		}

		.bottom {
			top: auto;
			bottom: 0;
		}
	}
	.horical {
		display: block;
		width: 100%;
		text-align: center;
		position: relative;

		&:hover {
			.carousel-control {
				display: block;
			}
		}
	}
	.check-box {
		padding-right: 5px;
		font-size: $font-size-base - 4;
	}
	.image {
		position: relative;
		text-align: center;
		overflow: hidden;
		border: 1px solid $border-color;
	}
	.share {
		@include clearfix;
		padding: 20px 0;
		display: none;
	}
	.wrap-qty {
		border: 1px solid $border-color;
		height: 32px;
		background-color: $white;
		padding: 3px;
		> div {
			display: table-cell;
			float: left;
		}
	}
	.quantity-adder {
		display: block;
		position: relative;
		background-color: $white;
		@include border-radius($border-color, 3px);
		.add-action {
			@include size(40px, 39px);
			color: $black;
			display: block;
			position: relative;
			cursor: pointer;
			font-size: 8px;
			line-height: 39px;
			text-align: center;
		}
		.quantity-number {
			input {
				@include size(58px, 35px);
				text-align: center;
				@include inline-block;
				@include rounded-corners(0);
				box-shadow: none;
				padding: 0;
				border: none;
				color: $black;
			}
		}
		.minimum {
			line-height: 38px;
			font-size: 11px;
			margin-left: 10px;
		}
	}
	#product {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.product-meta {
		display: flex;
		flex-direction: column;
		gap: 16px;

		a {
			padding: 0 19px !important;
			color: #ccd6dd;
			font-weight: 500;
			background: #555;
		}
		.cart {
			display: flex;
			flex-direction: column;
			gap: 16px;
			padding-bottom: 0;
			max-width: 277px;

			& > button {
				width: 100%;
			}
			.credit-btn-group {
				display: flex;
				gap: 8px;
			}
		}
	}
	.quantity {
		display: flex;
		gap: 8px;
		flex-wrap: wrap;
	}
}

.product-markdown-view {
	.cheaper-item, .cheaper-products, .cheaper-items, .cheaper-item-info {
		display: flex;
		flex-direction: column;
		gap: 16px;
	}
	.cheaper-item {
		padding: 20px;
		border: 1px solid $headings-color;
		max-width: 330px;

		&:not(.general) {
			border: 1px solid $text-color;

			&:hover {
				text-decoration: none;
				color: initial;
			}
		}
		&.general {
			margin-bottom: 20px;
		}
	}
	.cheaper-item, .cheaper-products {
		&__title {
			font-size: 16px;
			font-weight: 600;
			color: $headings-color;
		}
		&__status {
			color: $text-color;
			font-weight: 600;
		}
	}
}

.zoomWindowContainer .zoomWindow {
	left: 500px;
}

.horital {
	position: relative;
}

.owl-carousel {
	.owl-wrapper-outer {
		border: none;
		@include box-shadow(none);
		@include rounded-corners(0);
	}
}

.related {
	.owl-item {
		.product-col {
			border-left: 1px solid $border-color;
		}
		&:first-child {
			.product-col {
				border-left: 1px solid transparent;
			}
		}
	}
}

#input-captcha, #input-payment-captcha {
	margin-bottom: 10px;
}
.buttons-in-compare {
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	max-width: 150px;
	margin: 0 auto;
	.btn {
		width: 100px;
	}
}