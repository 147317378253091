
.owl-carousel-play .owl-carousel {
	margin: 0;
	border: none;
}

.products-block {
	.owl-carousel {
		margin: 0;
	}
}
.price-old {
	text-decoration: line-through;
}
.price {
	font-family: $product-price-font-family;
	font-weight: bold;
	display: flex;
	gap: 8px;
	align-items: flex-end;

	.price-new {
		color: $product-price-color;
		font-size: $product-price-font-size;
		line-height: 1;
	}
	.price-old {
		text-decoration: line-through;
		line-height: 1;
	}
	// page product detail
	&.detail {
		.price-new {
			font-size: $font-size-base + 11;
		}
		ul {
			margin: 0;
		}
	}
}

/* product style */
.product-v1 {
	.products-row {
		.product-col {
			border-left: 1px solid $border-color;
			&:first-child {
				border-left-color: transparent;
			}
		}
	}
	.product-block {
		.bottom {
			@include opacity(0);
			@include transition (all 400ms ease 0s);
			bottom: 60px;
			left: 20px;
			width: 100%;
			position: absolute;
			z-index: 999;
		}
		&:hover {
			.product-meta {
				.bottom {
					bottom: 120px;
					@include opacity(1) ;
				}
			}
		}
	}
}

.product-v3 {
	.product-block {
		.description {
			display: block;
			padding: 10px 0 0;
		}
		.action {
			display: none;
		}
		.price-new {
			font-size: $font-size-base + 5;
		}
		.price-old {
			font-size: $font-size-base + 1;
		}
	}
}

.product-v4 {
	padding: 0;
	.products-row {
		.border {
			border-bottom: $brand-border;
			border-right: $brand-border;
		}
		&.last {
			.border {
				border-bottom: transparent;
			}
		}
	}
	.product-block {
		&:hover {
			@include box-shadow(none !important);
		}
		.block-img {
			float: left;
			margin-right: 10px;
		}
		.name {
			margin: 5px 0;
		}
		.product-meta {
			padding: 20px 20px 0 0;
		}
		.bottom {
			display: none;
		}
	}
}

.product-block {
	position: relative;
	background-color: $white;
	border-top: 1px solid transparent;
	@include rounded-corners(3px);
	.description {
		display: none;
	}
	.product-meta {
		line-height: 25px;
		padding: 0;
	}
	.bottom {
		display: block;
	}
	.name {
		margin: 0;
		font-size: $font-size-base;
		font-family: $font-family-base;
		text-transform: inherit;
		overflow: hidden;
		font-weight: 400;
		a {
			padding: 0;
			color: $black;
			//    min-height: 60px;
			display: inline-block;
			line-height: 20px;
			&:hover {
				color: $theme-color;
			}
		}
	}
	.image {
		position: relative;
		overflow: hidden;
		@include transition(all 0.4s ease 0s);
		padding: 20px;
	}
	.top {
		position: relative;
		padding: 10px 20px;
	}
}

.cart {
	position: relative;
	@include transition(all 0.4s ease 0s);
	/*     z-index: 99; */
	padding-bottom: 15px;
	.icon-cart {
		background: url(#{$image-theme-path-base}icon-shopping.png) no-repeat scroll center center;
		display: block;
		@include size(16px, 17px);
	}
}

.image-container .product-label {
	z-index: 77;
}
.stickers {
	position: absolute;
	top: 17px;
	left: 15px;
	width: 50%;
	max-width: 140px;
	height: unset;
	z-index: 100;
	display: flex;
	flex-wrap: wrap;
}
.sticker {
	width: fit-content;
	margin: 3px 3px 3px 0;
	display: flex;
	align-items: center;
	border-radius: 3px;
	padding: 3px 10px;
	span {
		font-size: 12px;
		line-height: 17px;
		text-transform: uppercase;
		font-weight: 700;
		color: #fff;
	}
	svg {
		width: auto;
		height: 75%;
		padding-right: 4px;
	}
	&.recommend {
		background-color: #d91a22;
	}
	&.new {
		background-color: #08b149;;
	}
	&.markdown-product {
		background-color: #d91a22;
	}
}
.product-label {
	color: $product-label-color;
	font-size: $font-size-base - 2;
	font-weight: bold;
	text-align: center;
	position: absolute;
	text-transform: uppercase;
	&.bts {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 46px;
		height: 20px;
		position: absolute;
		top: 10px;
		background-color: $theme-color;
		right: 10px;
		border-radius: 2px;
		line-height: 20px;
		.product-label-special {
			display: block;
			width: 100%;
			height: 100%;
			position: relative;
			&:before {
				content: "";
				border-left: 4px solid transparent;
				border-right: 4px solid transparent;
				border-top: 4px solid $theme-color;
				height: 0;
				width: 0;
				position: absolute;
				left: 42%;
				bottom: -4px;
			}
		}
	}
	&.sale {
		text-align: center;
		top: 20px;
		background-color: $theme-color;
		right: 15px;
		line-height: 17px;
		@include rounded-corners(3px);
		padding: 3px 10px;
	}
	&.new-label {
		text-align: center;
		top: 20px;
		left: 15px;
		line-height: 17px;
		@include rounded-corners(3px);
		padding: 3px 10px;
		background: #08b149;
	}
	&.sale-border {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 40px;
		height: 20px;
		position: absolute;
		top: 15px;
		border: 2px solid #d95e41;
		right: 10px;
		color: #474747;
	}
	&.flag {
		text-align: center;
		display: block;
		text-transform: uppercase;
		width: 60px;
		height: 30px;
		position: absolute;
		top: 15px;
		background-color: $brand-success;
		left: 0;
		line-height: 30px;
		&:before {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			top: 0;
			border-top: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
		&:after {
			content: "";
			width: 0;
			height: 0;
			position: absolute;
			right: -15px;
			bottom: 0;
			border-bottom: 15px solid $brand-success;
			border-right: 15px solid transparent;
		}
	}
}

#tab-gifts {
	h3 {
		margin: 0 0 25px 0;
	}
	ul {
		padding: 0;
		list-style: none;
		li {
			&:after {
				content: '\0418\041B\0418';
				display: block;
				text-align: left;
				font-size: 18px;
				color: $black;
				margin: 15px 0;
			}
			&:last-child:after {
				content: none;
			}
		}
		a {
			display: flex;
			align-items: center;
		}
		div {
			display: inline-block;
			img {
				width: 120px;
			}
			&.gift_name {
				font-size: 18px;
				color: $black;
				margin-left: 25px;
				&:hover {
					color: $theme-color;
				}
			}
		}
	}
	p {
		margin: 20px 0 0 0;
		color: $black;
	}
	@media(max-width: 520px) {
		ul {
			li:after {
				text-align: center;
			}
			a {
				flex-wrap: wrap;
			}
		}
		div {
			width: 100%;
		}
		.gift_name {
			text-align: center;
		}
		.gift_image {
			text-align: center;
			margin-bottom: 20px;
		}
	}
}

.cart.pull-left {
	float: none !important;
	margin-bottom: 5px;
}

#tab-description {
	font-size: 18px;
	border: 1px solid #ddd;
	padding: 15px;
	background: #fff;
	.vc_row-flex, .vc_row-fluid {
		display: flex;
		align-items: center;
		div[class^="vc_col"] {
			display: flex;
			align-items: center;
			line-height: 31px;
			text-align: justify;
		}
		.vc_col-sm-3 {
			flex: 0 0 25%;
		}
		.vc_col-sm-6 {
			width: 50%;
		}
		.wpb_wrapper {
			width: 100%;
			table {
				width: 100%;
				tr {
					border: 1px solid #ddd;
					border-left: none;
					border-right: none;
					td {
						padding: 15px 0;
					}
				}
			}
		}
	}
	.vc_gitem-zone-mini {
		display: none;
	}
	.vc_clearfix {
		line-height: 0;
	}
	.vc_gitem-zone-img {
		padding: 0 !important;
	}
}